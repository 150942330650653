import React, { useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SidebarWithLinksAndSearch from "../components/common/SidebarWithLinksAndSearch"
import InnerPageHeader from "../components/molecules/InnerPageHeader"
import SearchBox from "../components/molecules/SearchBox"
import IconBox from "../components/molecules/IconBox"
import { iconBoxes } from "../Content/HelpAndSupport"
import RecommendedArticles from "../components/common/RecommendedArticles"

const HelpAndSupport = () => {
  const [searchQuery, setSearchQuery] = useState("")

  const handleSearchChange = event => {
    setSearchQuery(event.target.value)
  }

  return (
    <Layout>
      <Seo
        title="Help And Support"
        description={`Find comprehensive help and support for Synkli. Access guides, FAQs, and contact options to resolve issues and maximise your user experience.`}
      />
      <div className="synkli-section--horizontal-space synkli-section--vertical-space flex flex-col-reverse lg:flex-row gap-10">
        <div className="w-full lg:w-[25%]">
          <SidebarWithLinksAndSearch />
        </div>
        <div className="w-full lg:w-[75%]">
          <InnerPageHeader
            title={`What can we <span class="text-[#B695F8]">help</span> you with today?`}
            titleEC="text-center !max-w-[600px] !mx-auto"
            description="We are committed to providing you with the assistance you need. Whether you have questions, need technical support, or require guidance, our team is here to help."
            descriptionEC="text-center"
          />

          <SearchBox
            componentEC={"mx-auto max-w-[700px] mt-12"}
            placeholder={"Search Article"}
            onChange={handleSearchChange}
            value={searchQuery}
          />

          {searchQuery === "" && (
            <div className="flex flex-col md:flex-row items-stretch gap-5 mt-12">
              {iconBoxes.map((box, index) => (
                <IconBox
                  key={`icon_box_${index}`}
                  componentEC={box.componentEC}
                  IconBoxLink={box.link}
                  image={box.image}
                  imageEC={box.imageEC}
                  title={box.title}
                  titleEC={box.titleEC}
                  description={box.description}
                  descriptionEC={box.descriptionEC}
                />
              ))}
            </div>
          )}

          <div className="mt-20">
            <h2 className="text-[28px] leading-[1.2em] font-[600] text-[#000000] mb-6">
              All Articles
            </h2>
            <RecommendedArticles searchQuery={searchQuery} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default HelpAndSupport
