import PropTypes from "prop-types"
import React, { useState, useRef, useEffect } from "react"
import LinkComponent from "./atoms/Link"
import { Link } from "gatsby"
import ImageRenderer from "./atoms/ImageRenderer"
import { mainMenu, mainMenuExtraItemsForMobile } from "../Content/Header"

function Header({ siteTitle }) {
  const [isExpanded, toggleExpansion] = useState(false)
  const sidebarRef = useRef(null)

  // Close sidebar when clicking outside
  useEffect(() => {
    const handleClickOutside = event => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        toggleExpansion(false)
      }
    }

    // Add event listener when sidebar is open
    if (isExpanded) {
      document.addEventListener("mousedown", handleClickOutside)
    }

    // Remove event listener when the sidebar is closed
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [isExpanded])

  return (
    <div className="bg-[#0A1E46] pt-10">
      <div className="bg-white rounded-tl-[30px] rounded-tr-[30px] sm:rounded-tl-[50px] sm:rounded-tr-[50px]">
        <nav className="synkli-section--horizontal-space flex flex-wrap items-center justify-between pt-6 sm:pt-10 pb-4 mb-6">
          <div className="flex items-center flex-shrink-0 mr-6">
            <Link to="/">
              <div className="w-[110px]">
                <ImageRenderer
                  img={`synkli-logo-transparent`}
                  alt={"synkli-logo-transparent"}
                />
              </div>
            </Link>
          </div>
          <div className="block lg:hidden">
            <button
              onClick={() => toggleExpansion(true)}
              className="flex items-center px-3 py-2 border border-white rounded "
            >
              <svg
                className="w-6 h-6 fill-current"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>

          {/* Sidebar for mobile menu */}
          <div
            ref={sidebarRef}
            className={`fixed top-0 left-0 h-full w-[80%] max-w-xs bg-white shadow-lg transform transition-transform duration-300 ease-in-out ${
              isExpanded ? "translate-x-0" : "-translate-x-full"
            } z-50 lg:hidden`}
          >
            <div className="py-5 bg-[#050f23]"></div>
            <div className="flex items-center justify-between p-4">
              {/* Logo at the top */}
              <Link to="/" onClick={() => toggleExpansion(false)}>
                <div className="w-[110px]">
                  <ImageRenderer
                    img={`synkli-logo-transparent`}
                    alt={"synkli-logo-transparent"}
                  />
                </div>
              </Link>

              {/* Close button */}
              <button
                onClick={() => toggleExpansion(false)}
                className="text-black focus:outline-none"
              >
                <svg
                  className="w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            {/* Menu items in the middle */}
            <div className="px-4 py-4">
              {mainMenu.map((item, index) => (
                <LinkComponent
                  key={`menu_item_` + index}
                  text={item.text}
                  to={item.to}
                  ec={item.ec}
                  onClick={() => toggleExpansion(false)} // Close menu on item click
                />
              ))}
              {mainMenuExtraItemsForMobile.map((item, index) => (
                <LinkComponent
                  key={`menu_item_` + index}
                  text={item.text}
                  to={item.to}
                  ec={item.ec}
                  onClick={() => toggleExpansion(false)} // Close menu on item click
                />
              ))}
            </div>

            {/* Buttons at the bottom */}
            <div className="px-4 py-6 mt-auto">
              <a
                href="https://partners.synkli.com.au/signup/"
                className="block font-medium text-lg text-center mb-3 py-3 rounded-lg border-2 duration-300 primary-transparent-btn"
                rel="noopener noreferrer"
              >
                Signup
              </a>
              <a
                href="https://partners.synkli.com.au/"
                className="block font-medium text-lg text-center border-2 py-3 rounded-lg text-white transition duration-300 primary-btn"
                rel="noopener noreferrer"
              >
                Login
              </a>
            </div>
          </div>

          {/* Desktop menu */}
          <div className="hidden lg:flex gap-7 lg:items-center lg:w-auto">
            {mainMenu.map((item, index) => (
              <LinkComponent
                key={`menu_item_` + index}
                text={item.text}
                to={item.to}
                ec={item.ec}
              />
            ))}
          </div>

          <div
            className={`hidden lg:flex gap-3 lg:flex mt-4 lg:mt-0 header-btns`}
          >
            <a
              href="https://partners.synkli.com.au/signup/"
              className="block mt-0 sm:mt-4 lg:mt-0 font-medium text-[16px] sm:text-[18px] leading-[1.5em] w-auto px-4 sm:px-6 rounded-[10px] border-2 text-center py-3 transition duration-300 primary-transparent-btn w-[115px] lg:w-36"
              rel="noopener noreferrer"
            >
              Signup
            </a>
            <a
              href="https://partners.synkli.com.au/"
              className="block mt-0 sm:mt-4 lg:mt-0 font-medium text-[16px] sm:text-[18px] leading-[1.5em] w-auto px-4 sm:px-6 rounded-[10px] border-2 text-center py-3 transition duration-300 primary-btn w-[115px] lg:w-36"
              rel="noopener noreferrer"
            >
              Login
            </a>
          </div>
        </nav>

        {/* Overlay shadow when sidebar is open */}
        {isExpanded && (
          <div
            className="fixed inset-0 bg-black opacity-50 z-40"
            onClick={() => toggleExpansion(false)} // Close sidebar on overlay click
          />
        )}
      </div>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
