import React from "react"
import LinkComponent from "../atoms/Link"

const LinksList = ({ text, link }) => {
  return (
    <li className="mb-3">
      <LinkComponent 
        to={link || "#"}
        href={link || "#"}
        text={text}
        ec={`!text-white !text-[15px] !leading-[1.4em] !font-[200]`}
      />
    </li>
  )
}

export default LinksList
