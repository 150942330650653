// author details
export const author = {
    name: "Kaleem Ulah",
    image:{
        path: "blog-author-image",
        alt: "blog-author-image"
    },
    description: `Kaleem is CEO & Author at "Synkli". With more than 10 years of experience in financial services, He built Kalculators to transform your financial challenges into strategic triumphs!`
}

// articlesPostTypeSlug
export const articlesPostTypeSlug = `/articles/`

// blogsPostTypeSlug
export const blogsPostTypeSlug = `/blogs/`

// caseStudiesPostTypeSlug
export const caseStudiesPostTypeSlug = `/case-studies/`

// caseStudiesPostTypeSlug
export const featuresPostTypeSlug = `/features/`

// Synkli app links
export const appLinks = {
    googlePlay: `https://play.google.com/store/apps/details?id=com.synkli.au&hl=en&pli=1`,
    applePay: `https://apps.apple.com/au/app/synkli/id6479931845`
}