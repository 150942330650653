import { Link } from "gatsby"
import React from "react"

const LinkComponent = ({ to, href, text, ec }) => {
  return (
    <>
      <Link
        to={to || `/`}
        href={href || ""}
        className={
          "block mt-4 lg:inline-block lg:mt-0 font-medium text-lg leading-9 font-poppins !text-[#0A1E46] " +
            ec || ""
        }
      >
        {text || ""}
      </Link>
    </>
  )
}

export default LinkComponent
