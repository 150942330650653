/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./Footer"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import ScrollToTopOnLoad from "./common/ScrollToTopOnLoad"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const location = useLocation();
  const canonicalUrl = `https://www.synkli.com.au${location.pathname}`;

  return (
    <>
      <Helmet>
        <meta
          name="p:domain_verify"
          content="1305a3d0684d126aa1b68afe376b3e37"
        />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
        <link rel="canonical" href={canonicalUrl} />

        {/* <!-- Google Tag Manager --> */}
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-MLSN36JJ');`}
        </script>
        {/* <!-- End Google Tag Manager --> */}

        {/* <!-- Google Tag Manager (noscript) --> */}
        <noscript>
          {`
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-MLSN36JJ"
            height="0"
            width="0"
            style="display:none;visibility:hidden"
          ></iframe>
          `}
        </noscript>
        {/* <!-- End Google Tag Manager (noscript) --> */}

        {/* FreshDesk Widget starts */}
        <script>
          {`
            window.fwSettings = {
              'widget_id': 153000002291
            };
            !function() {
              if ("function" != typeof window.FreshworksWidget) {
                var n = function() {
                  n.q.push(arguments)
                };
                n.q = [];
                window.FreshworksWidget = n;
              }
            }()
          `}
        </script>
        <script
          type="text/javascript"
          src="https://widget.freshworks.com/widgets/153000002291.js"
          async
          defer
        ></script>
        {/* FreshDesk Widget ends */}
      </Helmet>
      <ScrollToTopOnLoad />
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
