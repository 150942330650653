import { Link } from "gatsby"
import React from "react"

const Button = ({ to, href, text, ec }) => {
  return (
    <>
      <Link
        to={to || `/`}
        href={href || ""}
        className={
          "block mt-0 sm:mt-4 lg:mt-0 font-medium text-[16px] sm:text-[18px] leading-[1.5em] w-auto px-4 sm:px-6 rounded-[10px] border-2 text-center py-3 transition duration-300 " +
            ec || ""
        }
      >
        {text || ""}
      </Link>
    </>
  )
}

export default Button
