import { blogsPostTypeSlug, caseStudiesPostTypeSlug } from "./Global"

// Main Menu
export const mainMenu = [
    {
        text: `Accountants`,
        to: `/accountants-practice-management/`,
        ec: `header-menu-item`
    },
    {
        text: `Business & Individuals`,
        to: `/small-businesses/`,
        ec: `header-menu-item`
    },
    {
        text: `Features`,
        to: `/features/`,
        ec: `header-menu-item`
    },
    {
        text: `About Us`,
        to: `/about-us/`,
        ec: `header-menu-item`
    },
    {
        text: `Contact Us`,
        to: `/contact-us/`,
        ec: `header-menu-item`
    },
]
export const mainMenuExtraItemsForMobile = [
    {
        text: `Blogs`,
        to: blogsPostTypeSlug,
        ec: `header-menu-item`
    },
    {
        text: `Case Studies`,
        to: caseStudiesPostTypeSlug,
        ec: `header-menu-item`
    },
    {
        text: `Help & Support`,
        to: `/help-and-support/`,
        ec: `header-menu-item`
    },
]