import React from "react"
import Button from "./Button"

const NewsletterForm = () => {
  return (
    <form className="relative">
      <input
        className="bg-[white] placeholder-gray px-3 sm:px-6 py-[16px] sm:py-5 text-[14px] sm:text-[16px] rounded-[12px] w-full min-w-[255px] font-[400] outline-none"
        placeholder="Your Email"
      />
      <Button
        to={`/`}
        href={""}
        text="Subscribe"
        ec={
          "secondary-btn !bg-[#B695F8] hover:!bg-[#0A1E46] hover:!border-[#0A1E46] !text-white inline !px-3 !py-[10px] sm:!py-3 absolute right-[3px] sm:right-[5px] top-[2.5px] sm:top-[4px] !mt-0"
        }
      />
    </form>
  )
}

export default NewsletterForm
