import React from "react"
import LinksList from "./molecules/LinksList"
import ImageRenderer from "./atoms/ImageRenderer"
import NewsletterForm from "./atoms/NewsletterForm"
import { about, account, features, help, quickLinks } from "../Content/Footer"
import { Link } from "gatsby"
import { appLinks } from "../Content/Global"

const Footer = () => {
  return (
    <div className="bg-[#0A1E46] mt-16">
      <div className="synkli-section--horizontal-space pt-12 sm:pt-24 pb-12 sm:pb-14">
        <div className="flex flex-wrap md:flex-nowrap gap-5">
          <div className="md:flex-[2] w-[45%] sm:w-[48%] md:w-full">
            <h3 className="text-white text-[18px] leading-[1.2em] mb-5">
              Quick Links
            </h3>
            <ul>
              {quickLinks.map((obj, index) => (
                <LinksList
                  key={"quickLinks_" + index}
                  text={obj.text}
                  link={obj.link}
                />
              ))}
            </ul>
          </div>
          <div className="md:flex-[2] w-[45%] sm:w-[48%] md:w-full">
            <h3 className="text-white text-[18px] leading-[1.2em] mb-5">
              Account
            </h3>
            <ul>
              {account.map((obj, index) => (
                <LinksList
                  key={"account_" + index}
                  text={obj.text}
                  link={obj.link}
                />
              ))}
            </ul>
          </div>
          <div className="md:flex-[2] w-[45%] sm:w-[48%] md:w-full">
            <h3 className="text-white text-[18px] leading-[1.2em] mb-5">
              Help
            </h3>
            <ul>
              {help.map((obj, index) => (
                <LinksList
                  key={"help_" + index}
                  text={obj.text}
                  link={obj.link}
                />
              ))}
            </ul>
          </div>
          <div className="md:flex-[1] w-[45%] sm:w-[48%] md:w-full">
            <h3 className="text-white text-[18px] leading-[1.2em] mb-5">
              About
            </h3>
            <ul>
              {about.map((obj, index) => (
                <LinksList
                  key={"about_" + index}
                  text={obj.text}
                  link={obj.link}
                />
              ))}
            </ul>
          </div>
        </div>
        {/* <div className="flex flex-wrap md:flex-nowrap gap-5 mt-7">
          <div className="md:flex-[2]  w-[45%] sm:w-[48%] md:w-full">
            <h3 className="text-white text-[18px] leading-[1.2em] mb-5">
              Features
            </h3>
            <ul>
              {features.map((obj, index) => (
                <LinksList
                  key={"features_" + index}
                  text={obj.text}
                  link={obj.link}
                />
              ))}
            </ul>
          </div>
          <div className="md:flex-[2] w-[45%] sm:w-[48%] md:w-full">
            <h3 className="text-white text-[18px] leading-[1.2em] mb-5">
              Features
            </h3>
            <ul>
              {features.map((obj, index) => (
                <LinksList
                  key={"features_" + index}
                  text={obj.text}
                  link={obj.link}
                />
              ))}
            </ul>
          </div>
          <div className="md:flex-[2] w-[45%] sm:w-[48%] md:w-full">
            <h3 className="text-white text-[18px] leading-[1.2em] mb-5">
              Features
            </h3>
            <ul>
              {features.map((obj, index) => (
                <LinksList
                  key={"features_" + index}
                  text={obj.text}
                  link={obj.link}
                />
              ))}
            </ul>
          </div>
          <div className="md:flex-[1] w-[45%] sm:w-[48%] md:w-full">
            <h3 className="text-white text-[18px] leading-[1.2em] mb-5">
              Features
            </h3>
            <ul>
              {features.map((obj, index) => (
                <LinksList
                  key={"features_" + index}
                  text={obj.text}
                  link={obj.link}
                />
              ))}
            </ul>
          </div>
        </div> */}
      </div>
      {/* <hr />
      <div className="synkli-section--horizontal-space pt-12 sm:pt-16 pb-12 sm:pb-16">
        <div className="flex flex-col lg:flex-row items-stretch lg:items-center gap-10">
          <div className="flex-[2]">
            <div className="max-w-[135px] sm:max-w-[126px] mb-7">
              <Link to="/">
                <ImageRenderer
                  img={"synkli-logo-white-transparent"}
                  alt={"Synkli Logo"}
                />
              </Link>
            </div>
            <p className="text-white text-[15px] font-[200] max-w-[400px]">
              Empowering Business Owners and Accountants with Intuitive
              Accounting Software Solutions. Streamline Your Financial
              Management Processes Effortlessly.
            </p>
          </div>
          <div className="flex-[2]">
            <p className="text-white text-[22px] md:text-[26px] font-[300] max-w-[380px]">
              Get the Latest Updates and Insights with Our Newsletter!
            </p>
          </div>
          <div className="flex-[2]">
            <NewsletterForm />
          </div>
        </div>
      </div> */}
      <hr />
      <div className="synkli-section--horizontal-space pt-12 sm:pt-15 pb-6 sm:pb-12">
        <div className="flex flex-col md:flex-row gap-10 justify-between">
          <div className="flex-[2]">
            <p className="text-white text-[15px] font-[200] max-w-[660px]">
              Advanced Accounting Software Tailored for Business Owners and
              Accounting Professionals. Our innovative platform seamlessly
              integrates with your workflow, simplifying bookkeeping, Invoices,
              and financial reporting. Whether You're a Small Business Owner or
              a Seasoned Accountant, Synkli Provides the Tools and Insights You
              Need to Manage Finances Efficiently, Track Expenses, and Maximise
              Profitability. Experience the Difference in Accounting Efficiency
              Today with Synkli.
            </p>
          </div>
          <div className="flex-[1] flex flex-wrap items-center max-w-[300px] md:max-w-[none]">
            <div className="flex gap-4">
              <div className="max-w-[200px] md:max-w-[200px] w-full">
                <Link
                  to={appLinks.googlePlay}
                  href={appLinks.googlePlay}
                  target="_blank"
                >
                  <ImageRenderer img={"google-play"} alt="Google Play" />
                </Link>
              </div>
              <div className="max-w-[135px] md:max-w-[180px] w-full">
                <Link
                  to={appLinks.applePay}
                  href={appLinks.applePay}
                  target="_blank"
                >
                  <ImageRenderer img={"apple-pay"} alt="Apple Pay" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-10 justify-between mt-7">
          <div className="flex-[2]">
            <p className="text-white text-[16px] font-[200]">
              © Copyright 2024. All Rights Reserved.
            </p>
          </div>
          <div className="flex-[1]">
            <div className="flex gap-4">
              <div className="max-w-[30px] transition duration-300 ease-in-out  hover:opacity-[0.5]">
                <Link
                  to="https://www.facebook.com/Synkli.Official/"
                  href="https://www.facebook.com/Synkli.Official/"
                  target="_blank"
                >
                  <ImageRenderer img={"facebook-icon"} alt="Facebook Icon" />
                </Link>
              </div>
              <div className="max-w-[30px] transition duration-300 ease-in-out  hover:opacity-[0.5]">
                <Link
                  to="https://www.instagram.com/synkli.official/"
                  href="https://www.instagram.com/synkli.official/"
                  target="_blank"
                >
                  <ImageRenderer img={"instagram-icon"} alt="Instagram Icon" />
                </Link>
              </div>
              <div className="max-w-[30px] transition duration-300 ease-in-out  hover:opacity-[0.5]">
                <Link
                  to="https://www.pinterest.com/synkli/"
                  href="https://www.pinterest.com/synkli/"
                  target="_blank"
                >
                  <ImageRenderer img={"pinterest-icon"} alt="Pinterest Icon" />
                </Link>
              </div>
              <div className="max-w-[30px] transition duration-300 ease-in-out  hover:opacity-[0.5]">
                <Link
                  to="https://x.com/Synkli157373"
                  href="https://x.com/Synkli157373"
                  target="_blank"
                >
                  <ImageRenderer img={"twitter-icon"} alt="Twitter Icon" />
                </Link>
              </div>
              <div className="max-w-[30px] transition duration-300 ease-in-out  hover:opacity-[0.5]">
                <Link
                  to="https://www.linkedin.com/company/synkli/"
                  href="https://www.linkedin.com/company/synkli/"
                  target="_blank"
                >
                  <ImageRenderer img={"linkedin-icon"} alt="Linkedin Icon" />
                </Link>
              </div>
              <div className="max-w-[30px] transition duration-300 ease-in-out  hover:opacity-[0.5]">
                <Link
                  to="https://www.youtube.com/@Synkli.Official"
                  href="https://www.youtube.com/@Synkli.Official"
                  target="_blank"
                >
                  <ImageRenderer img={"youtube-icon"} alt="Youtube Icon" />
                </Link>
              </div>
              <div className="max-w-[30px] transition duration-300 ease-in-out  hover:opacity-[0.5]">
                <Link
                  to="https://www.tiktok.com/@synkli.official"
                  href="https://www.tiktok.com/@synkli.official"
                  target="_blank"
                >
                  <ImageRenderer img={"tiktok-icon"} alt="Tiktok Icon" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
