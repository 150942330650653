import { stripHtmlTags } from "../utils/utils";
import { articles } from "./Articles";
import { articlesPostTypeSlug, blogsPostTypeSlug, caseStudiesPostTypeSlug, featuresPostTypeSlug } from "./Global";

export const quickLinks = [
  {
    text: "Accountants",
    link: "/accountants-practice-management/",
  },
  {
    text: "Business & Individuals",
    link: "/small-businesses/",
  },
  {
    text: "Features",
    link: featuresPostTypeSlug,
  },
  {
    text: "About Us",
    link: "/about-us/",
  },
  {
    text: "Contact Us",
    link: "/contact-us/",
  },
]



const sidebarLinks = articles.map(article => ({
  text: stripHtmlTags(article.title),  // Changed 'title' to 'text' to match the 'help' array format
  link: articlesPostTypeSlug + article.slug + "/"   // Changed 'titleSlug' to 'link' to match the 'help' array format
})).slice(0, 4);

export const help = [
  {
    text: "Help & Support",
    link: "/help-and-support/",
  },
  ...sidebarLinks
];



export const account = [
  {
    text: "Login",
    link: "https://partners.synkli.com.au/",
  },
  {
    text: "Signup",
    link: "https://partners.synkli.com.au/signup/",
  },
  {
    text: "Announcements",
    link: "https://partners.synkli.com.au/announcement/",
  },
  {
    text: "Workspace",
    link: "https://partners.synkli.com.au/workspace/",
  },
  {
    text: "Leads",
    link: "https://partners.synkli.com.au/leads/",
  },
]


export const about = [
  {
    text: "Blogs",
    link: blogsPostTypeSlug,
  },
  {
    text: "Case Studies",
    link: caseStudiesPostTypeSlug,
  },
  {
    text: "Privacy Policy",
    link: "/privacy-policy/",
  },
  {
    text: "Terms & Conditions",
    link: "/terms-and-conditions/",
  },
]

export const features = [
  {
    text: "Workspace Management",
    link: "#",
  },
  {
    text: "Client Management",
    link: "#",
  },
  {
    text: "Form Creation",
    link: "#",
  },
  {
    text: "Invoicer",
    link: "#",
  },
  {
    text: "Effortless Integration",
    link: "#",
  },
  {
    text: "Appointment Booking",
    link: "#",
  },
  {
    text: "Theme Customization",
    link: "#",
  },
  {
    text: "Client Portal",
    link: "#",
  },
  {
    text: "Accountant Portal",
    link: "#",
  },
  {
    text: "Super Admin Portal",
    link: "#",
  },
  {
    text: "Workflow Automation",
    link: "#",
  },
]
